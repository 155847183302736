/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// CSS
require('../css/app-mobile.css');


// JS
require('./lazy-line-painter-1.9.3.min.js');
require('./main.js');
require('./cuvees.js')

// Image s
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

$(document).ready(function() {
    if (!!$.cookie('verify')) {
        $('.verify').addClass('off');
        // $('html, body').css({ overflow: 'auto', height: 'auto' });
    } else {
        $('.verify').removeClass('off');
        // $('html, body').css({ overflow: 'hidden', height: '100%' });
    }
    $(".cb_age").change(function() {
        if(this.checked) {
            $('.btn-age').removeClass('disabled');
        } else {
            $('.btn-age').addClass('disabled');
        }
    });
    $('.btn-age').click(function() {
        if(!$(this).hasClass('disabled')) {
            validate_age();
        }
    });
});
function validate_age() {
    $.cookie('verify', 'ok', { expires: 7});
    $('.verify').addClass('off');
    // $('html, body').css({ overflow: 'auto', height: 'auto' });
}